import React, { useState, useEffect } from "react";
import "./ResidentalVerification.css";
import imageCompression from "browser-image-compression";
import { useNavigate } from "react-router-dom";

const ResidentalVerification = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isOwnHouse, setIsOwnHouse] = useState(false);
  const [isRentHouse, setIsRentHouse] = useState(false);

  const [incorrect, setIncorrect] = useState(false);
  const [success, setSuccess] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  const hideTenancy = () => {
    const selected = document.getElementById("tenancy1").value;
    setIsOwnHouse(selected === "Rented");
    setIsRentHouse(selected === "Owned");
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token"));
    const userInfo = JSON.parse(localStorage.getItem("user-info"));

    if (token && token.login) {
      setUserData(userInfo);
    } else {
      navigate("/");
    }
  }, [navigate]);


  const userInfo = JSON.parse(localStorage.getItem("user-info"));

  const API_URL = "https://humhai.in/residential/";


  const uploadResidentialData = async (formData) => {
    try {
      const response = await fetch(API_URL, {
        method: "POST",
        body: formData,
      });

      console.log("Response Status:", response.status);

      const data = await response.json();
      console.log("Response Data:", data);

      return response.status === 201
        ? { success: true }
        : { success: false, message: "Form Submission Failed" };
    } catch (error) {
      console.error("Error submitting data:", error);
      return { success: false, message: error.message };
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const hhid = generateHHID();
    setSubmissionCount(submissionCount + 1);

    const data = new FormData();
    Object.keys(formData).forEach((key) => {
      if (key !== "residencial_house_photos") {
        data.append(key, formData[key] || "");
      }
    });

    formData.residencial_house_photos.forEach((photo, index) => {
      if (photo.photo) data.append(`residencial_house_photo${index + 1}`, photo.photo);
      if (photo.remark) data.append(`remark_photo${index + 1}`, photo.remark);
    });

    data.append("residencial_hh_id", hhid);
    data.append("residencial_user_form_submit", userInfo ? `${userInfo.first_name} ${userInfo.last_name}` : "");
    data.append("residencial_submission_date", new Date().toISOString().split("T")[0]);

    try {
      const { success, message } = await uploadResidentialData(data);

      if (success) {
        alert("Form Submitted Successfully");
        setSuccess(true);
        window.location.reload();
      } else {
        alert(message);
        setIncorrect(true);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      alert("Error submitting data. Please try again.");
      setIncorrect(true);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = async (event, index, field) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const options = {
          maxSizeMB: 0.2,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };

        const compressedFile = await imageCompression(file, options);
        setFormData((prev) => {
          const updatedPhotos = [...prev.residencial_house_photos];
          updatedPhotos[index] = { ...updatedPhotos[index], [field]: compressedFile };

          return { ...prev, residencial_house_photos: updatedPhotos };
        });
      } catch (error) {
        console.error("Image compression error:", error);
      }
    }
  };



  const handleChange = (e, index = null, field = null) => {
    const { name, type, files, value } = e.target;

    setFormData((prev) => {
      if (index !== null && field) {
        const updatedPhotos = [...prev.residencial_house_photos];

        if (type === "file" && files.length > 0) {
          updatedPhotos[index] = { ...updatedPhotos[index], [field]: files[0] };
        } else {
          updatedPhotos[index] = { ...updatedPhotos[index], [field]: value };
        }

        return { ...prev, residencial_house_photos: updatedPhotos };
      }

      return { ...prev, [name]: value };
    });
  };


  const initialState = {
    residencial_name: "",
    residencial_fatherName: "",
    residencial_motherName: "",
    residencial_address: "",
    residencial_number: "",
    residencial_dob: "",
    residencial_gender: "",
    residencial_family_member: "",
    residencial_location: "",

    residencial_house_photos: Array(5).fill({ photo: null, remark: "" }),

    residencial_reference_name: "",
    residencial_reference1_mobilenumber: "",
    residencial_reference1_relation: "",
    residencial_reference1_remark: "",
    residencial_reference2_name: "",
    residencial_reference2_mobilenumber: "",
    residencial_reference2_relation: "",
    residencial_reference2_remark: "",

    residencial_reference3_name: "",
    residencial_reference3_mobilenumber: "",
    residencial_reference3_relation: "",
    residencial_reference3_remark: "",
    residencial_reference4_name: "",
    residencial_reference4_mobilenumber: "",
    residencial_reference4_relation: "",
    residencial_reference4_remark: "",

    residencial_neighbour_name: "",
    residencial_neighbour1_mobilenumber: "",
    residencial_neighbour1_address: "",
    residencial_neighbour1_remark: "",
    residencial_neighbour2_name: "",
    residencial_neighbour2_mobilenumber: "",
    residencial_neighbour2_address: "",
    residencial_neighbour2_remark: "",

    residencial_is_own_house: false,
    residencial_owner_name: "",
    residencial_owner_contact: "",
    residing_date: "",
    residing_remark: "",
    own_house_living: "",

    residencial_bike: "",
    residencial_car: "",
    residencial_ac: "",
    residencial_other: "",
  };
  const [formData, setFormData] = useState(initialState);

  const [submissionCount, setSubmissionCount] = useState(0);

  const generateHHID = (idType = "R") => {
    const now = new Date();
    const hhidPrefix = "HH";
    const year = now.getFullYear().toString().slice(-2);
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now.getDate().toString().padStart(2, "0");
    const hour = now.getHours().toString().padStart(2, "0");
    const minute = now.getMinutes().toString().padStart(2, "0");
    const second = now.getSeconds().toString().padStart(2, "0");

    const newSubmissionCount = submissionCount + 1;
    setSubmissionCount(newSubmissionCount);

    const countPart = newSubmissionCount.toString().padStart(4, "0");

    return `${hhidPrefix}${year}${month}${day}${hour}${minute}${second}${idType}${countPart}`;
  };
  const nextStep1 = () => {
    setCurrentStep((prev) => prev + 1)
  }

  const prevStep = () => {
    setCurrentStep((prev) => prev - 1)
  }

  const handleImageAdd = () => {
    setFormData((prev) => ({
      ...prev,
      residencial_house_photos: [
        ...prev.residencial_house_photos,
        { photo: null, remark: "" },
      ],
    }));
  };


  return (
    <div className="residental_containers">
      <div className="residental_container">
        <h1
          className="h1 text-center mb-5"
          style={{ marginTop: "20px", fontWeight: "bold" }}
        >
          Residencial<span style={{ color: "rgb(255, 119, 0)" }}> Verification</span>
          <span> Form</span>
        </h1>
        <div>
          {currentStep === 1 && (
            <div>
              {/*  Personal Details  */}
              <div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      name="residencial_name"
                      className="form-control"
                      placeholder="Enter Your Full Name"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="familyNumber">How Many Family Members</label>
                    <input
                      type="number"
                      className="form-control"
                      name="residencial_family_member"
                      placeholder="Enter family members"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="residencial_address">Address</label>
                    <input
                      type="text"
                      className="form-control"
                      name="residencial_address"
                      placeholder="Enter Address"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="residencial_number">Contact Number</label>
                    <input
                      type="number"
                      className="form-control"
                      name="residencial_number"
                      onChange={handleChange}
                      placeholder="Enter Contact Number"
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="residencial_gender">Gender</label>
                    <select
                      className="form-control"
                      name="residencial_gender"
                      onChange={handleChange}
                    >
                      <option value="">Choose</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="residencial_dob">Date Of Birth</label>
                    <input
                      type="date"
                      className="form-control"
                      onChange={handleChange}
                      placeholder="Enter DOB"
                      name="residencial_dob"
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="residencial_location">Location</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      placeholder="Enter Location"
                      name="residencial_location"
                    />
                  </div>
                </div>
              </div>

              {/* Reference 1  */}
              <div>
                <h3
                  className="h3 text-center mb-1"
                  style={{
                    marginTop: "20px",
                    fontWeight: "bold",
                    fontSize: "34px",
                  }}
                >
                  Reference Details
                </h3>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="residencial_reference_name">
                      Reference Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="residencial_reference_name"
                      placeholder="Enter Reference Name"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="residencial_reference1_relation">
                      Reference Relation
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="residencial_reference1_relation"
                      placeholder="Enter Reference Relation"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="residencial_reference1_mobilenumber">
                      Reference 1 Mobile Number
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="residencial_reference1_mobilenumber"
                      placeholder="Enter Reference 1 Mobile Number"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="residencial_reference1_remark">
                      Reference 1 Remark
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="residencial_reference1_remark"
                      placeholder="Enter Reference 1 Remark"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="residencial_reference2_name">
                      Reference 2 Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="residencial_reference2_name"
                      placeholder="Enter Reference 2 Name"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="residencial_reference2_relation">
                      Reference Relation
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="residencial_reference2_relation"
                      placeholder="Enter Reference 2 Relation"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="residencial_reference2_mobilenumber">
                      Reference 2 Mobile Number
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="residencial_reference2_mobilenumber"
                      placeholder="Enter Reference 2 Mobile Number"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <label htmlFor="residencial_reference2_remark">
                      Reference 2 Remark
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="residencial_reference2_remark"
                      placeholder="Enter Reference 2 Remark"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                {/* <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="residencial_reference3_name">
                  Reference 3 Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="residencial_reference3_name"
                  placeholder="Enter Reference 3 Name"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="residencial_reference3_relation">
                  Reference 3 Relation
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="residencial_reference3_relation"
                  placeholder="Enter Reference 3 Relation"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="residencial_reference3_mobilenumber">
                  Reference 3 Mobile Number
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="residencial_reference3_mobilenumber"
                  placeholder="Enter Reference 3 Mobile Number"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="residencial_reference3_remark">
                  Reference 3 Remark
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="residencial_reference3_remark"
                  placeholder="Enter Reference 3 Remark"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="residencial_reference4_name">
                  Reference 4 Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="residencial_reference4_name"
                  placeholder="Enter Reference 4 Name"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="residencial_reference4_relation">
                  Reference 4 Relation
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="residencial_reference4_relation"
                  placeholder="Enter Reference 4 Relation"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="residencial_reference4_mobilenumber">
                  Reference 4 Mobile Number
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="residencial_reference4_mobilenumber"
                  placeholder="Enter Reference 4 Mobile Number"
                  onChange={handleChange}
                />
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="residencial_reference4_remark">
                  Reference 4 Remark
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="residencial_reference4_remark"
                  placeholder="Enter Reference 4 Remark"
                  onChange={handleChange}
                />
              </div>
            </div> */}
              </div>

              {/* neighbour 1  */}
              <div>
                <h3
                  className="h3 text-center mb-1"
                  style={{
                    marginTop: "20px",
                    fontWeight: "bold",
                    fontSize: "34px",
                  }}
                >
                  Neighbour Details
                </h3>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="residencial_neighbour_name">
                      Neighbour Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="residencial_neighbour_name"
                      placeholder="Enter Neighbour Name"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="residencial_neighbour1_mobilenumber">
                      Neighbour 1 Mobile Number
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="residencial_neighbour1_mobilenumber"
                      placeholder="Enter Neighbour 1 Mobile Number"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="residencial_neighbour1_address">
                      Neighbour 1 Since
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="residencial_neighbour1_address"
                      placeholder="Enter Neighbour 1 Since"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="residencial_neighbour1_remark">
                      Neighbour 1 Remark
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="residencial_neighbour1_remark"
                      placeholder="Enter Neighbour 1 Remark"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="residencial_neighbour2_name">
                      Neighbour 2 Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="residencial_neighbour2_name"
                      placeholder="Enter Neighbour 2 Name"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="residencial_neighbour2_mobilenumber">
                      Neighbour 2 Mobile Number
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="residencial_neighbour2_mobilenumber"
                      placeholder="Enter Neighbour 2 Mobile Number"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="residencial_neighbour2_address">
                      Neighbour 2 Since
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="residencial_neighbour2_address"
                      placeholder="Enter Neighbour 2 Since"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="residencial_neighbour2_remark">
                      Neighbour 2 Remark
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="residencial_neighbour2_remark"
                      placeholder="Enter Neighbour 2 Remark"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="ms-4 mb-4 ">
                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn btn-primary w-100"
                    style={{ margin: "10px 0px", height: "50px" }}
                    onClick={nextStep1}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          )}
          {currentStep === 2 && (
            <div>
              <div>
                {/* House Photos */}
                <div>
                  <div>
                    <h3
                      className="h3 text-center mb-1"
                      style={{
                        marginTop: "20px",
                        fontWeight: "bold",
                        fontSize: "34px",
                      }}
                    >
                      House Photos Details
                    </h3>
                  </div>

                  <div>
                    <div>
                      {formData.residencial_house_photos.map((item, index) => (
                        <div className="form-row" key={index}>
                          {/* File Input */}
                          <div className="form-group col-md-6">
                            <label>House Photo {index + 1}</label>
                            <input
                              type="file"
                              accept="image/*"
                              capture="environment"
                              className="form-control"
                              onChange={(e) => handleFileChange(e, index, "photo")}
                            />
                          </div>

                          {/* Remark Input */}
                          <div className="form-group col-md-6">
                            <label>House Photo {index + 1} Remark</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder={`Enter House Photo ${index + 1} Remark`}
                              value={item.remark}
                              onChange={(e) => handleChange(e, index, "remark")}
                            />
                          </div>
                        </div>
                      ))}

                      {/* Add More Photos Button */}
                      <button onClick={handleImageAdd} className="btn btn-primary mt-3">
                        Add More
                      </button>
                    </div>
                  </div>

                </div>

                {/* House Ownership */}
                <div>
                  <h3
                    className="h3 text-center mb-1"
                    style={{
                      marginTop: "20px",
                      fontWeight: "bold",
                      fontSize: "34px",
                    }}
                  >
                    House Ownership
                  </h3>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="tenancy1">House Ownership</label>
                      <select
                        id="tenancy1"
                        className="form-control"
                        onChange={hideTenancy}
                      >
                        <option value="">Choose</option>
                        <option value="Owned">Owned</option>
                        <option value="Rented">Rented</option>
                      </select>
                    </div>
                  </div>
                  {isOwnHouse && (
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="residencial_owner_name">
                          House Owner Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="residencial_owner_name"
                          placeholder="Enter House Owner Name"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="residencial_owner_contact">
                          House Owner Contact
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="residencial_owner_contact"
                          placeholder="Enter House Owner Contact"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="residencial_dob">Residing Date</label>
                        <input
                          type="date"
                          className="form-control"
                          onChange={handleChange}
                          name="residing_date"
                          placeholder="Enter Contact Number"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="residing_remark">Remark</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          name="residing_remark"
                          placeholder="Enter Contact Number"
                        />
                      </div>
                    </div>
                  )}
                  {isRentHouse && (
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="own_house_living">
                          How long have you been living in this house?
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          name="own_house_living"
                          placeholder="Enter Total Years"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="residing_remark">Remark</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          name="residing_remark"
                          placeholder="Enter Remark"
                        />
                      </div>
                    </div>
                  )}
                </div>

                {/* Vehicle Details */}
                <div>
                  <h3
                    className="h3 text-center mb-1"
                    style={{
                      marginTop: "20px",
                      fontWeight: "bold",
                      fontSize: "34px",
                    }}
                  >
                    Remarks
                  </h3>

                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label htmlFor="residencial_other">Remarks</label>
                      <textarea
                        type="text"
                        className="form-control"
                        name="residencial_other"
                        placeholder="Enter Remarks"
                        onChange={handleChange}
                        rows="4"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="ms-4 mb-4">
                <div className="col-md-12 d-flex">
                  <button
                    type="button"
                    style={{ margin: "10px 10px 10px 0", height: "50px" }}
                    className="btn btn-primary w-100"
                    onClick={prevStep}
                    disabled={loading}
                  >
                    Previous
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary w-100"
                    style={{ margin: "10px 0px", height: "50px" }}
                    disabled={loading}
                    onClick={handleSubmit}
                  >
                    {loading ? "Loading..." : "Submit"}
                  </button>
                </div>
              </div>

            </div>
          )}

        </div>
      </div>
    </div>
  );
};

export default ResidentalVerification;
